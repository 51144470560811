.popup {
  &.base-rental-rate-custom-table,
  &.edit-rental-rate-escalation-custom-table {
    form {
      height: 100%;
      display: flex;
      flex-direction: column;

      .dx-scrollview {
        flex: 1;
        overflow: hidden;
      }

      .footer {
        padding: 20px;
        background-color: #f2f2f2;
      }
    }
  }
}
